import type { UserContact, RepoContact, TypedContact } from "@openqlabs/drm-db";
import React, { createContext, useCallback, useContext } from "react";
import { useTeamAccount } from "~/providers/TeamAccountProvider";
import { api, type RouterOutputs } from "~/server/trpc/react";
import { useLogger } from "./LoggerProvider";
import { useListContact } from "./ListContactProvider";

type RefetchFunc = () => Promise<void>;
type ContactOpts = RepoContact | UserContact | null;

const GithubContactContext = createContext<
  | {
      githubContact: ContactOpts;
      refetchGithubContact: RefetchFunc;
      contactWithChildEvaluations: RouterOutputs["githubContact"]["getWithChildEvaluations"];
    }
  | object
>({});

export function useGithubContact<T>() {
  const context = useContext(GithubContactContext) as unknown as {
    githubContact: TypedContact<T> | null;
    refetchGithubContact: RefetchFunc;
    contactWithChildEvaluations: RouterOutputs["githubContact"]["getWithChildEvaluations"];
  };
  return context;
}

interface ProviderProps {
  githubContactId?: string;
  initialContact?: ContactOpts;
  children: React.ReactNode;
}

export function GithubContactProvider({
  children,
  initialContact = null,
}: ProviderProps) {
  const logger = useLogger();
  const { listContact } = useListContact();
  const githubContactId = listContact?.githubContactId;
  const { activeTeamAccount } = useTeamAccount();
  const { refetch, data: githubContact } = api.githubContact.get.useQuery({
    githubContactId,
    teamAccountId: activeTeamAccount.id,
  });

  const { data: contactWithChildEvaluations } =
    api.githubContact.getWithChildEvaluations.useQuery({
      githubContactId,
      teamAccountId: activeTeamAccount.id,
    });

  const refetchGithubContact = useCallback(async () => {
    await refetch();
  }, [refetch]);

  if (!initialContact)
    logger.info("no initial contact", "GithubContactProvider.tsx");

  return (
    <GithubContactContext.Provider
      value={{
        githubContact,
        contactWithChildEvaluations,
        refetchGithubContact,
      }}
    >
      {children}
    </GithubContactContext.Provider>
  );
}
