import { createContext, useContext, type ReactNode } from "react";
import useId from "~/hooks/useId";
import { api, type RouterOutputs } from "~/server/trpc/react";
import { useLogger } from "./LoggerProvider";
import { useTeamAccount } from "./TeamAccountProvider";

export type TeamRenderContact =
  | RouterOutputs["listContact"]["getContactsByListId"]["items"][number]
  | null;
type TeamRenderContactDeep = RouterOutputs["listContact"]["get"] | null;

const listContactContext = createContext<{
  listContact: TeamRenderContactDeep;
  isLoadingListContact: boolean;
  updateListContact: (contact: Partial<TeamRenderContactDeep>) => void;
  refetchListContact: () => void;
}>({
  listContact: null,
  isLoadingListContact: true,
  updateListContact: () => {
    return null;
  },
  refetchListContact: () => {
    return null;
  },
});

export const useListContact = () => {
  const context = useContext(listContactContext);
  return {
    ...context,

    listContact: context.listContact as NonNullable<TeamRenderContactDeep>,
  };
};

export const ListContactProvider = ({
  children,
  id,
  hasPlaceholder,
}: {
  children: ReactNode | ReactNode[];
  id?: string;
  hasPlaceholder?: boolean;
}) => {
  const currentId = useId("listContactId", id);
  const logger = useLogger();
  const { activeTeamAccount } = useTeamAccount();
  const teamAccountId = activeTeamAccount?.id ?? "";
  const utils = api.useUtils();

  const handleUpdateContact = (contact: Partial<TeamRenderContactDeep>) => {
    utils.listContact.get.setData(
      { teamAccountId, id: contact?.id as string },
      contact as RouterOutputs["listContact"]["get"]
    );
  };

  const { mutate: updateContact } = api.listContact.update.useMutation({
    onSuccess: (data) => {
      handleUpdateContact(data);
    },
  });
  const {
    data: listContact,
    refetch: refetchListContact,
    isLoading,
  } = api.listContact.get.useQuery(
    {
      teamAccountId: teamAccountId,
      id: currentId as string,
    },
    {
      enabled: !!currentId,
    }
  );
  const handleRefetch = () => {
    refetchListContact().catch((err) =>
      logger.error(err, "ContactProvider.tsx")
    );
  };
  const listContactUpdater = (listContact: Partial<TeamRenderContactDeep>) => {
    if (!listContact) throw new Error("statefulContact is null");
    updateContact({
      ...listContact,
      id: listContact?.id as string,
      teamAccountId: teamAccountId,
    });
  };

  if (!listContact) {
    if (hasPlaceholder) {
      return children;
    }

    return <></>;
  } else
    return (
      <listContactContext.Provider
        value={{
          listContact,
          updateListContact: listContactUpdater,
          refetchListContact: handleRefetch,
          isLoadingListContact: isLoading,
        }}
      >
        {children}
      </listContactContext.Provider>
    );
};
